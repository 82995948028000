import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAccounts } from "../../../../services/accountService";
import { createTransfer } from "../../../../services/transactionService";


interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedTransactions: Transaction[] | null;
    bank: string;
}

const AccountingModal: React.FC<Props> = ({
    open,
    onClose,
    selectedTransactions,
    bank,
}) => {

    const [accounts, setAccounts] = useState<Account[]>([])
    const [selectedAccount, setSelectedAccount] = useState<Account | null>()
    const [description, setDescription] = useState<string>("")
    const [loading, setLoading] = useState(false)

    const fetchAccounts = async () => {
        const Accounts = await getAccounts()
        setAccounts(Accounts)
    }

    useEffect(() => {
        fetchAccounts()
    }, [])

    const handleSubmit = async () => {
        if (!selectedTransactions || selectedTransactions.length === 0 || !selectedAccount) {
            alert("Seleccione una cuenta contable")
            return;
        }
        setLoading(true)
        const transactionsIds = selectedTransactions.map((transaction) => transaction._id)
        const response = await createTransfer(transactionsIds, selectedAccount.accountId, bank, description)
        if (response) {
            alert("Traspaso realizado con exito")
            onClose()
        }
        setLoading(false)

    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Seleccione cuenta contable para el movimiento</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 my-2">
                    <Autocomplete
                        sx={{ width: "90%" }}
                        options={accounts}
                        getOptionLabel={(option) => `${option.accountId}-${option.name}` || ""}

                        value={selectedAccount}
                        onChange={(event, newValue) => {
                            setSelectedAccount(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Account" variant="outlined" />
                        )}
                    />
                    <TextField
                        label="Descripcion"
                        variant="outlined"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
            </DialogContent>


            <DialogActions>
                <Button onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                >Enviar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccountingModal;