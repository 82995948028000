import { useEffect, useState } from "react";
import { Button, Card, TextField } from "@mui/material"
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro"
import { getCustomers, deleteCustomer } from "../../../services/customerService"

import CustomerModal from "./CustomerModal";
import { useAppSelector } from "../../../hooks/storeHooks";



const CustomersView = () => {

    const [selectedCustomer, setSelectedCustomer ] = useState<Customer | null>(null);
    const [ openModal, setOpenModal ] = useState<boolean>(false);
    const { isLoading, customers } = useAppSelector((state) => state.customersReducer);
    const [ rutFilter, setRutFilter ] = useState<string>("");
    const [ transactionFilter, setTransactionFilter ] = useState<string>("");
    const [ showedRows, setShowedRows ] = useState<Customer[]>([]);

    const columns: GridColDef[] = [

        { field: "_id", headerName: "ID cliente", width: 250 },
        { field: "rut", headerName: "Rut", width: 150 },
        { field: "transactionInfo", headerName: "Detalle de transacción", width: 500 },
        {
            field: 'edit', headerName: 'Editar', width: 120, renderCell: (params) => {
                return (
                    <Button variant="contained" color="primary" onClick={() => {
                        setSelectedCustomer(params.row as Customer);
                        setOpenModal(true);}}>
                            Editar
                    </Button>
                )
            }
        },
        {
            field: 'delete', headerName: 'Eliminar', width: 120, renderCell: (params) => {
                return (
                    <Button variant="contained" color="secondary" onClick={() =>
                        deleteCustomer(params.row._id)}>
                            Eliminar
                    </Button>
                )
            }
        }
    ]
    
    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        setShowedRows(customers);
    }, [customers]);

    useEffect(() => {
        if (rutFilter === "" && transactionFilter === "") {
            setShowedRows(customers)
        } else {
            const filteredRows = customers.filter((customer) => {
                return (
                    customer.rut.includes(rutFilter) &&
                    customer.transactionInfo.includes(transactionFilter)
                );
            });
            setShowedRows(filteredRows)
        }
        
    }, [rutFilter, transactionFilter])



    return (
        <div className="p-5 h-full flex flex-col">
        <div className="mt-5 h-full" >
            
            <div className="flex flex-row items-center justify-between">

                <div  className="flex flex-row gap-2">
                    <div className="mb-2">
                        <h3 className="my-2">Filtrar por Rut</h3>
                        <TextField
                            label="Rut"
                            variant="outlined"
                            value={rutFilter}
                            onChange={(e) => {setRutFilter(e.target.value)}}
                            fullWidth
                        />
                    </div>

                    <div className="mb-2 min-w-72">
                        <h3 className="my-2">Filtrar por Detalle</h3>
                        <TextField
                            label="Detalle de transaccion"
                            variant="outlined"
                            value={transactionFilter}
                            onChange={(e) => {setTransactionFilter(e.target.value)}}
                            fullWidth
                        />
                    </div>
                </div>

                <div className="mt-2 mb-3 flex justify-end pr-7 space-x-2">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSelectedCustomer(null);
                            setOpenModal(true);
                        }}
                    >
                        Agregar Cliente
                    </Button>
                </div>
            </div>

            <Card>

                <DataGridPro
                    autoHeight
                    rows={showedRows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    loading={isLoading}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    pagination
                    pageSize={25}
                    rowsPerPageOptions={[10, 25, 50]}
                />
            </Card>
            

            <CustomerModal
                customer={selectedCustomer}
                open={openModal}
                onClose={() => setOpenModal(false)}
                setCustomer={setSelectedCustomer}
            ></CustomerModal>
        </div>
        </div>
    )
}

export default CustomersView