import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import Layout from "./components/Layout";
import { Routes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/views/Login/Login";
import "./App.css";
import Home from "./components/views/Home/Home";
import CustomersView from "./components/views/Customers/CustomersView";
import IncomesView from "./components/views/Transactions/Sales/IncomesView";
import ExpensesView from "./components/views/Transactions/Purchases/ExpensesView";
import SuppliersView from "./components/views/Suppliers/SuppliersView";


function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#FFF",
      },
      primary: {
        main: "#ED6E11",
      },
      secondary: {
        main: "#BF549A",
      },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      initLogin().finally(() => setLoading(false));
    };
    init();
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          {loading ? (
            <div className="h-full flex justify-center items-center">
              <div className="animate-spin">
                <Icon size={6} path={mdiCircleSlice1}></Icon>
              </div>
            </div>
          ) : (
            <AppRoutes>
              <Route
                path={Routes.Home}
                element={<ProtectedRoute element={<Home />}></ProtectedRoute>}
              ></Route>

              <Route
                path={Routes.Incomes}
                element={<ProtectedRoute element={<IncomesView />}></ProtectedRoute>}
              ></Route>

              <Route
                path={Routes.Expenses}
                element={<ProtectedRoute element={<ExpensesView />}></ProtectedRoute>}
              ></Route>

              <Route
                path={Routes.Customers}
                element={<ProtectedRoute element={<CustomersView />}></ProtectedRoute>}
              ></Route>

              <Route
                path={Routes.Suppliers}
                element={<ProtectedRoute element={<SuppliersView />}></ProtectedRoute>}
              ></Route>

              <Route path={Routes.Login} element={<Login />}></Route>
            </AppRoutes>
          )}
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
