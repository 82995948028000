import axios from "./client";
import { CustomerActions } from "../reducers/CustomersReducer";
import store from "../store";

const API_URL = process.env.REACT_APP_API_URL;

export const getCustomers = async () => {
    try {
        store.dispatch({ type: CustomerActions.RequestCustomers });
        const { data } = await axios.get<Customer[]>(`${API_URL}customers/`);
        store.dispatch({ type: CustomerActions.ReceiveCustomers, payload: data });
    } catch (error) {
        alert((error as any).response.data);
        console.log(error);
        store.dispatch({ type: CustomerActions.ReceiveCustomers, payload: [] });
        return [];
    }
}

export const createCustomer = async (customerData: Partial<Customer>) => {
    try {
        const { data } = await axios.post(`${API_URL}customers/`, customerData);
        store.dispatch({ type: CustomerActions.AddCustomer, payload: data });
        return data
    } catch (error) {
        alert((error as any).response.data);
        console.log(error);
    }
}

export const updateCustomer = async (id: string, customerData: Partial<Customer>) => {
    try {
        const { data } = await axios.put(`${API_URL}customers/${id}`, customerData);
        store.dispatch({ type: CustomerActions.AddCustomer, payload: data });
        return data;
      } catch (error) {
        alert((error as any).response.data);
        console.log(error);
      }
}

export const deleteCustomer = async (id: string) => {
    try {
        await axios.delete<Customer[]>(`${API_URL}customers/${id}`);
        store.dispatch({ type: CustomerActions.DeleteCustomer, payload: id });
      } catch (error) {
        alert((error as any).response.data);
        console.log(error);
      }
}