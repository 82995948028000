import axios from "./client";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_URL;

export const getTransactionsByBank = async (bank: string, transactionType: string) => {
    try {
        const response = await axios.get(`${API_URL}transactions/${bank}`, {
            params: {
                transactionType: transactionType
            }
            });
        return response.data;
    } catch (error) {
    console.error(error);
    return [];
    }
}

export const createBankMovement = async (transactionsIds: string[], newBank: string, bankOfMovement: string) => {
    try {
        await axios.post(`${API_URL}transactions/bankMovement`, {
            transactionsIds: transactionsIds,
            newBank: newBank,
            bankOfMovement: bankOfMovement

        });
        return true;
    } catch (error: any) {
        alert(error!.response!.data.msg);
    return false;
    }
}

export const createPayment = async (transactionId: string, invoicesId: string[]) => {
    try {
        await axios.post(`${API_URL}transactions/payment/incomes`, {
            transactionId,
            invoicesId
        });
        alert("Transaccion conciliada con exito")
        return true;
    } catch (error:any) {
        alert(error!.response!.data.msg);
    return false;
    }
}

export const createPurchasePayment = async (transactionsIds: string[], invoicesIds: string[], extraText: string) => {
    try {
    
        await axios.post(`${API_URL}transactions/payment/expenses`, {
            transactionsIds,
            invoicesIds,
            extraText
        });
        alert("Transacciones conciliada con exito")
        return true;

    } catch (error: any) {
        alert(error!.response!.data.msg);
    return false;
    }
}

export const generateTransactionsXls = async (bank: string, transactionType: string) => {
    await axios
    .get(`${API_URL}transactions/excel/${bank}`, {
        responseType: "blob",
        headers: {
            "content-type": "application/vnd.ms-excel;charset=UTF-8",
        },
        params: {
            transactionType, // Agrega el parámetro de consulta aquí
        },
    })
    .then((res) => {
        FileDownload(res.data, `${bank}.xlsx`);
    });

}

export const createAdvance = async (transactionId: string, customerId: string) => {
    try {
    await axios.post(`${API_URL}transactions/advance`, {
        transactionId,
        customerId
    });
    return true;
    } catch (error) {
        return false;
    }
}

export const createTransfer = async (transactionsIds: string[], accountId: number, bankOfMovement: string, description: string) => {
    try {
        await axios.post(`${API_URL}transactions/accountTransfer`, {
            transactionsIds: transactionsIds,
            accountId: accountId,
            bankOfMovement: bankOfMovement,
            description: description

        });
        return true;
    } catch (error: any) {
        alert(error!.response!.data.msg);
    return false;
    }
}
