interface State {
    customers: Customer[],
    isLoading: boolean,

  }
  
  const initialState: State = {
    customers: [],
    isLoading: false
  };
  
  export enum CustomerActions {
    RequestCustomers = "REQUEST_CUSTOMERS",
    ReceiveCustomers = "RECEIVE_CUSTOMERS",
    AddCustomer = "ADD_CUSTOMER",
    DeleteCustomer = "DELETE_CUSTOMER"
  }
  

  // el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
  const reducer = (
    state: State = initialState,
    { type, payload }: { type: CustomerActions; payload: any }
  ): State => {
    switch (type) {
      case CustomerActions.RequestCustomers:
        return { ...state, isLoading: true };
      
      // payload = customers: Customer[]
      case CustomerActions.ReceiveCustomers:
        return { ...state, isLoading: false, customers: payload };

      // payload = customer: Customer
      case CustomerActions.AddCustomer:
        const updatedCustomerIndex = state.customers.findIndex(customer => customer._id === payload._id);
        if (updatedCustomerIndex !== -1) {
          const updatedCustomers = [...state.customers];
          updatedCustomers[updatedCustomerIndex] = payload;
          return { ...state, isLoading: false, customers: updatedCustomers,
          };
        } else {
          return {...state, isLoading: false, customers: [...state.customers, payload],
          };
        }
      
      // payload = id: string 
      case CustomerActions.DeleteCustomer:
        return { ...state, customers: state.customers.filter(customer => customer._id !== payload) };
      default:
        return state;
    }
  };
  
  export default reducer;
  