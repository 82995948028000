import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getAccounts = async () => {
    try {
        const { data } = await axios.get<Account[]>(`${API_URL}accounts/`);
        return data
    } catch (error) {
        alert((error as any).response.data);
        console.log(error);
        return [];
    }
}
