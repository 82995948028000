import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import customersReducer from "./CustomersReducer";
import suppliersReducer from "./SuppliersReducer";


const rootReducer = combineReducers({
  authReducer,
  customersReducer,
  suppliersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
