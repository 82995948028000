import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from "@mui/material";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    setOpenInvoiceModal: (value: boolean) => void;
    setOpenCheckModal: (value: boolean) => void;
    setOpenAdvanceModal: (value: boolean) => void;
}

const InvoiceOrCheckModal: React.FC<Props> = ({
    open,
    onClose,
    setOpenInvoiceModal,
    setOpenCheckModal,
    setOpenAdvanceModal,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>¿Conciliar con Cheque, Factura o Anticipo?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenCheckModal(true);

                    }}
                >
                    Cheque
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenInvoiceModal(true);
                    }}
                >
                    Factura
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenAdvanceModal(true);
                    }}
                >
                    Anticipo
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceOrCheckModal;