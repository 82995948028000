import axios from "./client";
import { SupplierActions } from "../reducers/SuppliersReducer";
import store from "../store";

const API_URL = process.env.REACT_APP_API_URL;

export const getSuppliers = async () => {
    try {
        store.dispatch({ type: SupplierActions.RequestSuppliers });
        const { data } = await axios.get<Supplier[]>(`${API_URL}suppliers/`);
        store.dispatch({ type: SupplierActions.ReceiveSuppliers, payload: data });
    } catch (error) {
        alert((error as any).response.data);
        console.log(error);
        store.dispatch({ type: SupplierActions.ReceiveSuppliers, payload: [] });
        return [];
    }
}

export const createSupplier = async (supplierData: Partial<Supplier>) => {
    try {
        const { data } = await axios.post(`${API_URL}suppliers/`, supplierData);
        store.dispatch({ type: SupplierActions.AddSupplier, payload: data });
        return data
    } catch (error) {
        alert((error as any).response.data);
        console.log(error);
    }
}

export const updateSupplier = async (id: string, supplierData: Partial<Supplier>) => {
    try {
        const { data } = await axios.put(`${API_URL}suppliers/${id}`, supplierData);
        store.dispatch({ type: SupplierActions.AddSupplier, payload: data });
        return data;
      } catch (error) {
        alert((error as any).response.data);
        console.log(error);
      }
}

export const deleteSupplier = async (id: string) => {
    try {
        await axios.delete<Supplier[]>(`${API_URL}suppliers/${id}`);
        store.dispatch({ type: SupplierActions.DeleteSupplier, payload: id });
      } catch (error) {
        alert((error as any).response.data);
        console.log(error);
      }
}