interface State {
    suppliers: Supplier[],
    isLoading: boolean,

  }
  
  const initialState: State = {
    suppliers: [],
    isLoading: false
  };
  
  export enum SupplierActions {
    RequestSuppliers = "REQUEST_SUPPLIERS",
    ReceiveSuppliers = "RECEIVE_SUPPLIERS",
    AddSupplier = "ADD_SUPPLIER",
    DeleteSupplier = "DELETE_SUPPLIER"
  }
  

  // el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
  const reducer = (
    state: State = initialState,
    { type, payload }: { type: SupplierActions; payload: any }
  ): State => {
    switch (type) {
      case SupplierActions.RequestSuppliers:
        return { ...state, isLoading: true };
      
      // payload = suppliers: Supplier[]
      case SupplierActions.ReceiveSuppliers:
        return { ...state, isLoading: false, suppliers: payload };

      // payload = supplier: Supplier
      case SupplierActions.AddSupplier:
        const updatedSupplierIndex = state.suppliers.findIndex(supplier => supplier._id === payload._id);
        if (updatedSupplierIndex !== -1) {
          const updatedSuppliers = [...state.suppliers];
          updatedSuppliers[updatedSupplierIndex] = payload;
          return { ...state, isLoading: false, suppliers: updatedSuppliers,
          };
        } else {
          return {...state, isLoading: false, suppliers: [...state.suppliers, payload],
          };
        }
      
      // payload = id: string 
      case SupplierActions.DeleteSupplier:
        return { ...state, suppliers: state.suppliers.filter(supplier => supplier._id !== payload) };
      default:
        return state;
    }
  };
  
  export default reducer;
  