import { useEffect, useState } from "react";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material"
import { createSupplier, updateSupplier } from "../../../services/supplierService"



interface Props {
    supplier: Supplier | null;
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    setSupplier: (supplier: Supplier | null) => void;
}

const SupplierModal: React.FC<Props> = ({ open, onClose, supplier, setSupplier }) => {
    
    const initialValues: Supplier = {
        _id: "" as string,
        transactionInfo: "" as string,
        rut: "" as string,
    };
    const [values, setValues] = useState(initialValues);


    useEffect(() => {
        if (supplier) {
            setValues({
                ...supplier,
                transactionInfo: supplier.transactionInfo,
                rut: supplier.rut,
            });
        }
        else {
            setValues(initialValues);
        }
    }, [supplier]);



    return (
        <Dialog 
        key={supplier ? supplier._id : 'new-agent'}
        open={open}
        onClose={onClose}
        onTransitionEnd={() => !open && setValues(initialValues)}>

        <DialogTitle>{!!supplier ? "Editar" : "Nuevo"} Proveedor</DialogTitle>

            { supplier && <h3>Id de proveedor: {supplier._id} </h3> }
            { supplier && <br/> }

            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">

                <TextField
                    onChange={(e) => setValues({ ...values, rut: e.target.value as string })}
                    id="rut"
                    label="Rut"
                    value={values.rut}
                ></TextField>

                <TextField
                    onChange={(e) => setValues({ ...values, transactionInfo: e.target.value as string })}
                    id="transactionInfo"
                    label="Detalle"
                    value={values.transactionInfo}
                ></TextField>

                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                >Cancelar</Button>
                <Button
                    variant="contained"
                    // disabled={}
                    onClick={() => {
                        if (supplier) {
                            updateSupplier(supplier._id, {rut: values.rut, transactionInfo: values.transactionInfo});
                        } else {
                            createSupplier({ rut: values.rut, transactionInfo: values.transactionInfo });
                        }
                        onClose();
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SupplierModal