import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getLaudusCustomersByTransactionInfo } from "../../../../services/laudusCustomerService";
import { createAdvance } from "../../../../services/transactionService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedTransaction: Transaction | null;
}

const AdvanceModal: React.FC<Props> = ({
    open,
    onClose,
    selectedTransaction,
}) => {
    const columns: GridColDef[] = [
        { field: "rut", headerName: "Rut", width: 150 },
        { field: "name", headerName: "Nombre", flex: 1 },
    ];
    const [rows, setRows] = useState<LaudusCustomer[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!selectedTransaction) return;
        getLaudusCustomersByTransactionInfo(selectedTransaction?.transactionInfo!).then((data) => {
            setRows(data);
        });
    }, [selectedTransaction]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '80vh', // Ajusta la altura mínima del diálogo aquí
                    minWidth: '80vw', // Ajusta el ancho del diálogo aquí si necesitas un valor específico que no sea uno de los predefinidos
                },
            }}
        >
            <DialogTitle>Selecciona el cliente para el anticipo</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: 400 }}>
                    <DataGridPro
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        loading={loading}
                        getRowId={(row) => row._id}
                        onRowDoubleClick={(params) => {
                            setLoading(true);
                            createAdvance(selectedTransaction?._id!, params.row._id).then((res) => {
                                if (res) {
                                    alert("Anticipo creado con éxito");
                                    onClose();
                                } else {
                                    alert("Error al crear anticipo");
                                }
                                setLoading(false);

                            });
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AdvanceModal