import { useEffect, useState } from "react";
import { Button, Card, TextField } from "@mui/material"
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro"
import { getSuppliers, deleteSupplier } from "../../../services/supplierService"

import SupplierModal from "./SupplierModal";
import { useAppSelector } from "../../../hooks/storeHooks";



const SuppliersView = () => {

    const [selectedSupplier, setSelectedSupplier ] = useState<Supplier | null>(null);
    const [ openModal, setOpenModal ] = useState<boolean>(false);
    const { isLoading, suppliers } = useAppSelector((state) => state.suppliersReducer);
    const [ rutFilter, setRutFilter ] = useState<string>("");
    const [ transactionFilter, setTransactionFilter ] = useState<string>("");
    const [ showedRows, setShowedRows ] = useState<Supplier[]>([]);

    const columns: GridColDef[] = [

        { field: "_id", headerName: "ID proveedor", width: 250 },
        { field: "rut", headerName: "Rut", width: 150 },
        { field: "transactionInfo", headerName: "Detalle de transacción", width: 500 },
        {
            field: 'edit', headerName: 'Editar', width: 120, renderCell: (params) => {
                return (
                    <Button variant="contained" color="primary" onClick={() => {
                        setSelectedSupplier(params.row as Supplier);
                        setOpenModal(true);}}>
                            Editar
                    </Button>
                )
            }
        },
        {
            field: 'delete', headerName: 'Eliminar', width: 120, renderCell: (params) => {
                return (
                    <Button variant="contained" color="secondary" onClick={() =>
                        deleteSupplier(params.row._id)}>
                            Eliminar
                    </Button>
                )
            }
        }
    ]
    
    useEffect(() => {
        getSuppliers();
    }, []);

    useEffect(() => {
        setShowedRows(suppliers);
    }, [suppliers]);

    useEffect(() => {
        if (rutFilter === "" && transactionFilter === "") {
            setShowedRows(suppliers)
        } else {
            const filteredRows = suppliers.filter((supplier) => {
                return (
                    supplier.rut.includes(rutFilter) &&
                    supplier.transactionInfo.includes(transactionFilter)
                );
            });
            setShowedRows(filteredRows)
        }
        
    }, [rutFilter, transactionFilter])



    return (
        <div className="p-5 h-full flex flex-col">
        <div className="mt-5 h-full" >
            
            <div className="flex flex-row items-center justify-between">

                <div  className="flex flex-row gap-2">
                    <div className="mb-2">
                        <h3 className="my-2">Filtrar por Rut</h3>
                        <TextField
                            label="Rut"
                            variant="outlined"
                            value={rutFilter}
                            onChange={(e) => {setRutFilter(e.target.value)}}
                            fullWidth
                        />
                    </div>

                    <div className="mb-2 min-w-72">
                        <h3 className="my-2">Filtrar por Detalle</h3>
                        <TextField
                            label="Detalle de transaccion"
                            variant="outlined"
                            value={transactionFilter}
                            onChange={(e) => {setTransactionFilter(e.target.value)}}
                            fullWidth
                        />
                    </div>
                </div>

                <div className="mt-2 mb-3 flex justify-end pr-7 space-x-2">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSelectedSupplier(null);
                            setOpenModal(true);
                        }}
                    >
                        Agregar Proveedor
                    </Button>
                </div>
            </div>

            <Card>

                <DataGridPro
                    autoHeight
                    rows={showedRows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    loading={isLoading}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    pagination
                    pageSize={25}
                    rowsPerPageOptions={[10, 25, 50]}
                />
            </Card>
            

            <SupplierModal
                supplier={selectedSupplier}
                open={openModal}
                onClose={() => setOpenModal(false)}
                setSupplier={setSelectedSupplier}
            ></SupplierModal>
        </div>
        </div>
    )
}

export default SuppliersView