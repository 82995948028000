import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from "@mui/material";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedTransactions: Transaction[] | null;
    setOpenPurchaseInvoiceModal: (value: boolean) => void;
    setOpenTransferModal: (value: boolean) => void;
    setOpenAccountingModal: (value: boolean) => void;
    setOpenPurchaseCheckModal: (value: boolean) => void;
}

const PurchaseOptionsModal: React.FC<Props> = ({
    open,
    onClose,
    selectedTransactions,
    setOpenPurchaseInvoiceModal,
    setOpenTransferModal,
    setOpenAccountingModal,
    setOpenPurchaseCheckModal,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>¿Contra Factura, Traspaso entre cuentas o Cuenta contable?</DialogTitle>
            <DialogActions>

                <Button className="mx-10 w-40" onClick={onClose} color="primary">
                    Cancelar
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenPurchaseInvoiceModal(true);
                    }}
                >
                    Contra Factura
                </Button>

                <Button
                    className="w-72"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenTransferModal(true);
                    }}
                >
                    Traspaso entre cuentas
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        onClose();
                        setOpenAccountingModal(true);
                    }}
                >
                    Cuenta contable
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedTransactions!.length !== 1}
                    onClick={() => {
                        onClose();
                        setOpenPurchaseCheckModal(true);
                    }}
                >
                    Conciliar cheque
                </Button>

            </DialogActions>
        </Dialog>
    );
};

export default PurchaseOptionsModal;