import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getPendingSalesInvoicesByTransactionInfo } from "../../../../services/salesInvoiceService";
import { createPayment } from "../../../../services/transactionService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedTransaction: Transaction | null;
}

const InvoicesModal: React.FC<Props> = ({
    open,
    onClose,
    selectedTransaction,
}) => {
    const columns: GridColDef[] = [
        { field: "docNumber", headerName: "Numero Factura", width: 150 },
        { field: "issuedDate", headerName: "Fecha Emision", width: 150, valueGetter(params: { row: { issuedDate: string | number | Date; }; }) { return new Date(params.row.issuedDate).toLocaleDateString("es-ES"); } },
        { field: "customerName", headerName: "Cliente", flex: 1 },
        { field: "customerVATId", headerName: "RUt", width: 150 },
        { field: "totalAmount", headerName: "Monto Total", width: 150, valueGetter(params: { row: { totalAmount: { toLocaleString: (arg0: string) => any; }; }; }) { return params.row.totalAmount.toLocaleString("es-CL") } },
        { field: "pendingAmount", headerName: "Monto Pendiente", width: 150, valueGetter(params: { row: { pendingAmount: { toLocaleString: (arg0: string) => any; }; }; }) { return params.row.pendingAmount.toLocaleString("es-CL") } },
    ];
    const [rows, setRows] = useState<SalesInvoice[]>([]);
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [adjustAmount, setAdjustAmount] = useState(false);
    const [loading, setLoading] = useState(false);

    const calculateTotalSelected = () => {
        let total = 0;
        selectionModel.forEach((element) => {
            total += rows.find((row) => row._id === element)?.pendingAmount || 0;
        });
        return total;
    }
    const calculateAnticipo = () => {
        const totalSelected = calculateTotalSelected();
        const totalTransaction = selectedTransaction?.amount ?? 0;
        return totalTransaction - totalSelected;
    }
    const handleSubmit = async () => {
        setLoading(true);
        await createPayment(selectedTransaction?._id!, selectionModel);
        setLoading(false);
        setSelectionModel([]);
        onClose()
    }

    useEffect(() => {
        if (!selectedTransaction) return;
        getPendingSalesInvoicesByTransactionInfo(selectedTransaction?.transactionInfo!).then((data) => {
            setRows(data);
        })
    }, [selectedTransaction]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '80vh', // Ajusta la altura mínima del diálogo aquí
                    minWidth: '80vw', // Ajusta el ancho del diálogo aquí si necesitas un valor específico que no sea uno de los predefinidos
                },
            }}
        >
            <DialogTitle>Seleccione Facturas a conciliar</DialogTitle>
            <DialogContent>
                <div>Monto Transaccion: {selectedTransaction?.amount}</div>
                <div>Monto Facturas: {calculateTotalSelected()}</div>
                <div>Monto Anticipo: {calculateAnticipo()}</div>
                {adjustAmount ?
                    (<div>
                        <p>El monto de la transaccion es menor a la suma de la cantidad de facturas seleccionadas, por ende, la factura más nueva se conciliara parcialmente.</p>
                        <div>
                            <Button variant="contained" onClick={
                                () => setAdjustAmount(false)
                            }>Volver</Button>
                            <Button variant="contained"
                                disabled={loading}
                                onClick={
                                    handleSubmit
                                }>Continuar</Button>
                        </div>
                    </div>) : (<Box>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row._id}
                            autoHeight
                            pagination
                            pageSize={10}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            checkboxSelection
                            onSelectionModelChange={(newRowSelectionModel) => {
                                setSelectionModel(newRowSelectionModel);
                            }}
                            selectionModel={selectionModel}

                        />
                    </Box>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={selectionModel.length === 0 || loading}
                    onClick={() => {
                        if (calculateAnticipo() < 0) {
                            setAdjustAmount(true);
                        }
                        else {
                            handleSubmit()
                        }
                    }
                    }
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoicesModal;