import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getPendingChecksByBank = async (bank: string, checkType: string) => {
    try {
    const response = await axios.get(`${API_URL}checks/pending/${bank}`, {
        params: { checkType },
    });
    return response.data;
    } catch (error) {
    console.error(error);
    return [];
    }
}

export const conciliateCheck = async (checkId: string, transactionId: string ) => {
    try {
    await axios.post(`${API_URL}checks/conciliate/sales`, {
        checkId,
        transactionId
    });
    return true;
    } catch (error) {
    console.error(error);
    return false;
    }
}

export const conciliatePaymentCheck = async (checkId: string, transactionId: string ) => {
    try {
    await axios.post(`${API_URL}checks/conciliate/payment`, {
        checkId,
        transactionId
    });
    return true;
    } catch (error) {
    console.error(error);
    return false;
    }
}