import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getPendingPurchaseInvoicesByTransactionInfo } from "../../../../services/purchaseInvoiceService";
import { createPurchasePayment } from "../../../../services/transactionService";



interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedTransactions: Transaction[] | null;
}

const PurchaseInvoicesModal: React.FC<Props> = ({
    open,
    onClose,
    selectedTransactions,
}) => {
    const columns: GridColDef[] = [
        { field: "docNumber", headerName: "Numero Factura", width: 150 },
        { field: "issuedDate", headerName: "Fecha Emision", width: 120, valueGetter(params: { row: { issuedDate: string | number | Date; }; }) { return new Date(params.row.issuedDate).toLocaleDateString("es-ES"); } },
        { field: "supplierName", headerName: "Proveedor", width: 200 },
        { field: "supplierVATId", headerName: "RUT", width: 150 },
        {
            field: "totalAmount",
            headerName: "Monto Total",
            width: 120,
            valueGetter(params: { row: { totalAmount: { toLocaleString: (arg0: string) => any; }; }; }) {
                return params.row.totalAmount.toLocaleString("es-CL")
            }
        },
        {
            field: "fixedAmount",
            headerName: "Monto con crédito descontado",
            width: 220,
            valueGetter(params: { row: { totalAmount: number; creditAmount: number; } }) {
                const totalAmount = params.row.totalAmount || 0;
                const creditAmount = params.row.creditAmount || 0;
                const discountedAmount = totalAmount - creditAmount;
                return discountedAmount.toLocaleString("es-CL");
            }
        }
    ];

    const [rows, setRows] = useState<PurchaseInvoice[]>([]);
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [documentExtra, setDocumentExtra] = useState<string>("");

    const calculateTotalSelected = () => {
        let total = 0;
        selectionModel.forEach((element) => {

            const selected = rows.find((row) => row._id === element)
            total += selected ? selected.totalAmount - selected.creditAmount : 0;
        });
        return total;
    }

    const calculateDocumentNumber = () => {
        const firstThreeSelections = selectionModel.slice(0, 3);
        return firstThreeSelections.map((element) => {
            const selected = rows.find((row) => row._id === element)
            return `${selected!.docNumber}`
        }).join("-");

    }

    const calculateTransactionTotal = () => {
        let total = 0;
        if (!selectedTransactions) return 0;
        selectedTransactions.forEach((element) => {
            total += element.amount;
        });
        return total;
    }

    const handleSubmit = async () => {
        setLoading(true);

        const transactionsIds = selectedTransactions!.map((transaction) => { return transaction._id })
        await createPurchasePayment(transactionsIds, selectionModel, documentExtra);
        setLoading(false);
        setSelectionModel([]);
        onClose()
    }

    useEffect(() => {
        if (!selectedTransactions || selectedTransactions.length === 0) return;
        // Se asume que todas tienen el mismo transactionInfo
        getPendingPurchaseInvoicesByTransactionInfo(selectedTransactions[0].transactionInfo!).then((data) => {
            setRows(data);
        })

    }, [selectedTransactions]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '80vh',
                    minWidth: '80vw',
                },
            }}
        >
            <DialogTitle>Seleccione Facturas a conciliar</DialogTitle>
            <DialogContent>
                <div>Monto Transaccion: {calculateTransactionTotal()}</div>
                <div>Cantidad de transacciones: {selectedTransactions ? selectedTransactions.length : 0}</div>
                <div>Monto Facturas: {calculateTotalSelected()}</div>
                <div className="flex gap-2">Nombre documento para laudus: {calculateDocumentNumber()}
                    <TextField
                        label="Extra"
                        variant="outlined"
                        fullWidth
                        value={documentExtra}
                        onChange={(e) => setDocumentExtra(e.target.value)}
                    />
                </div>
                <Box>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row._id}
                        autoHeight
                        pagination
                        pageSize={10}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        checkboxSelection
                        onSelectionModelChange={(newRowSelectionModel) => {
                            setSelectionModel(newRowSelectionModel);
                        }}
                        selectionModel={selectionModel}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose()
                    setSelectionModel([]);
                }} color="primary">
                    Cancelar
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={selectionModel.length === 0 || loading || calculateTransactionTotal() !== calculateTotalSelected()}
                    onClick={() => {
                        handleSubmit()
                    }
                    }
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseInvoicesModal;