import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getPendingPurchaseInvoices = async () => {
    try {
        const response = await axios.get(`${API_URL}purchaseInvoices/pending`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getPendingPurchaseInvoicesByTransactionInfo = async (transactionInfo: string) => {
    try {
        const response = await axios.get(`${API_URL}purchaseInvoices/pending/${transactionInfo}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const SyncPurchaseInvoices = async () => {
    try { 
        await axios.get(`${API_URL}purchaseInvoices/sync`);
        return true;
    }
    catch (error:any) {
        console.error(error);
        alert(error!.response!.data.msg);
        return false;
    }
}
