import { useEffect, useState } from "react";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material"
import { createCustomer, updateCustomer } from "../../../services/customerService"



interface Props {
    customer: Customer | null;
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    setCustomer: (customer: Customer | null) => void;
}

const CustomerModal: React.FC<Props> = ({ open, onClose, customer, setCustomer }) => {
    
    const initialValues: Customer = {
        _id: "" as string,
        transactionInfo: "" as string,
        rut: "" as string,
    };
    const [values, setValues] = useState(initialValues);


    useEffect(() => {
        if (customer) {
            setValues({
                ...customer,
                transactionInfo: customer.transactionInfo,
                rut: customer.rut,
            });
        }
        else {
            setValues(initialValues);
        }
    }, [customer]);



    return (
        <Dialog 
        key={customer ? customer._id : 'new-agent'}
        open={open}
        onClose={onClose}
        onTransitionEnd={() => !open && setValues(initialValues)}>

        <DialogTitle>{!!customer ? "Editar" : "Nuevo"} Cliente</DialogTitle>

            { customer && <h3>Cliente de id: {customer._id} </h3> }
            { customer && <br/> }

            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">

                <TextField
                    onChange={(e) => setValues({ ...values, rut: e.target.value as string })}
                    id="rut"
                    label="Rut"
                    value={values.rut}
                ></TextField>

                <TextField
                    onChange={(e) => setValues({ ...values, transactionInfo: e.target.value as string })}
                    id="transactionInfo"
                    label="Detalle"
                    value={values.transactionInfo}
                ></TextField>

                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                >Cancelar</Button>
                <Button
                    variant="contained"
                    // disabled={}
                    onClick={() => {
                        if (customer) {
                            updateCustomer(customer._id, {rut: values.rut, transactionInfo: values.transactionInfo});
                        } else {
                            createCustomer({ rut: values.rut, transactionInfo: values.transactionInfo });
                        }
                        onClose();
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomerModal