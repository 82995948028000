import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { conciliatePaymentCheck, getPendingChecksByBank } from "../../../../services/checkService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    bank: string;
    selectedTransactions: Transaction[] | null;
}

const PurchaseChecksModal: React.FC<Props> = ({
    open,
    onClose,
    bank,
    selectedTransactions,
}) => {
    const columns: GridColDef[] = [
        {
            field: "dueDate", headerName: "Fecha Deposito", width: 150, renderCell(params) {
                const date = new Date(params.row.dueDate).toLocaleDateString("es-ES");
                return <p>{date}</p>
            },
            sortComparator: (v1, v2) => {
                // Convert the date strings back to Date objects for comparison
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1.getTime() - date2.getTime();
            },
        },
        { field: "document", headerName: "Número de Cheque", width: 150 },
        { field: "totalAmount", headerName: "Monto", width: 150, valueGetter(params: { row: { totalAmount: any; }; }) { return params.row.totalAmount.toLocaleString("es-CL") }, },
        { field: "cpName", headerName: "Proveedor", width: 150 },
    ];
    const [rows, setRows] = useState<Check[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getPendingChecksByBank(bank, "payment").then((data) => {
            setRows(data);
        })
    }, [bank]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '80vh', // Ajusta la altura mínima del diálogo aquí
                    minWidth: '80vw', // Ajusta el ancho del diálogo aquí si necesitas un valor específico que no sea uno de los predefinidos
                },
            }}
        >
            <DialogTitle>Seleccione Cheque a conciliar</DialogTitle>
            <DialogContent>
                <Box>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row._id}
                        autoHeight
                        pagination
                        pageSize={10}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        onRowDoubleClick={async (params) => {
                            setLoading(true);
                            const checkId = params.row.checkId;
                            // REVISAR
                            const transactionId = selectedTransactions?.map((transaction => transaction._id));
                            await conciliatePaymentCheck(checkId, transactionId![0]).then((res) => {
                                if (res) {
                                    alert("Cheque conciliado con éxito");
                                } else {
                                    alert("Error al conciliar cheque");
                                }
                            }).catch(() => {
                                alert("Error al conciliar cheque");
                            }).finally(() => {
                                setLoading(false);
                                onClose();
                            });
                        }
                        }
                        loading={loading}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseChecksModal;