import { Box, Button, Card } from "@mui/material"
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react";
import { generateTransactionsXls, getTransactionsByBank } from "../../../services/transactionService";
import InvoiceOrCheckModal from "./Sales/InvoiceAdvanceOrCheckModal";
import ChecksModal from "./Sales/ChecksModal";
import InvoicesModal from "./Sales/InvoicesModal";
import AdvanceModal from "./Sales/AdvanceModal";
import AccountingModal from "./Purchases/AccountingModal";
import PurchaseInvoicesModal from "./Purchases/PurchaseInvoicesModal";
import PurchaseOptionsModal from "./Purchases/PurchaseTransactionOptions";
import TransferModal from "./Purchases/TransferModal";
import PurchaseChecksModal from "./Purchases/PurchaseChecksModal";


interface Props {
    bank: string;
    type: string;
}
const BankView: React.FC<Props> = ({ bank, type }) => {
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
    const [selectedTransactions, setSelectedTransactions] = useState<Transaction[] | null>([]);

    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [openCheckModal, setOpenCheckModal] = useState(false);
    const [openAdvanceModal, setOpenAdvanceModal] = useState(false);
    const [openInvoiceOrBankModal, setOpenInvoiceOrBankModal] = useState(false);

    const [openPurchaseInvoiceModal, setOpenPurchaseInvoiceModal] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [openAccountingModal, setOpenAccountingModal] = useState(false);
    const [openPurchaseOptionsModal, setOpenPurchaseOptionsModal] = useState(false);
    const [openPurchaseCheckModal, setOpenPurchaseCheckModal] = useState(false);



    const columns: GridColDef[] = [
        {
            field: "date", headerName: "Fecha", width: 150, renderCell(params) {
                const date = new Date(params.row.date).toLocaleDateString("es-ES");
                return <p>{date}</p>
            },
            sortComparator: (v1, v2) => {
                // Convert the date strings back to Date objects for comparison
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1.getTime() - date2.getTime();
            },
        },
        { field: "transactionInfo", headerName: "Detalle Movimiento", width: 300 },
        { field: "amount", headerName: "Monto", width: 150, valueGetter(params) { return params.row.amount.toLocaleString("es-CL") } },
        { field: "matched", headerName: "Conciliado", width: 150, valueGetter(params) { return params.row.matched ? "Si" : "No" } },
        {
            field: "laudusMatchId", headerName: "ID Laudus", width: 150
        }
    ]
    if (type === "Credit") {
        columns.push(
            { field: "customerRut", headerName: "Rut Cliente", width: 150 },
            { field: "customerName", headerName: "Nombre Cliente", flex: 1 }
        )
    } else if (type === "Debit") {
        columns.push(
            { field: "supplierRut", headerName: "Rut Proveedor", width: 150 },
            { field: "supplierName", headerName: "Nombre Proveedor", flex: 1 },
        )
    }

    const [rows, setRows] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectionModel, setSelectionModel] = useState<any[]>([]);

    const updateTransactions = () => {
        setLoading(true);
        getTransactionsByBank(bank, type).then((data) => {
            setRows(data);
        }).finally(() => setLoading(false));
    }

    const handleOpenSelectionModal = () => {
        const transactions: Transaction[] = []
        rows.forEach((transaction) => {
            if (selectionModel.includes(transaction._id)) {
                transactions.push(transaction)
            }
        })

        setSelectedTransactions(transactions)
        setOpenPurchaseOptionsModal(true)
    }

    useEffect(() => {
        getTransactionsByBank(bank, type).then((data) => {
            setRows(data);
        }).finally(() => setLoading(false));
    }, [bank]);


    return (
        <div className="mt-5 h-full" >
            <Card>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Button onClick={() => generateTransactionsXls(bank, type)}>Descargar Transacciones</Button>

                    {type === "Debit" &&
                        <Button className="" onClick={handleOpenSelectionModal} disabled={selectionModel.length === 0}>Seleccionar acción</Button>}
                </Box>

                <Box sx={{
                    height: '80vh',
                    width: '100%',
                }}>


                    {type === "Credit" ?
                        <DataGridPro
                            sx={{
                                '& .matched--true': {
                                    bgcolor: '#51d1f6',
                                },
                            }}
                            getRowClassName={(params) => `matched--${params.row.matched}`}

                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row._id}
                            loading={loading}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            pagination
                            onRowDoubleClick={(params) => {
                                if (params.row.matched) return;
                                setSelectedTransaction(params.row as Transaction);
                                type === "Credit" ? setOpenInvoiceOrBankModal(true) : setOpenPurchaseOptionsModal(true);
                            }
                            }
                        /> :

                        <DataGridPro
                            sx={{
                                '& .matched--true': {
                                    bgcolor: '#51d1f6',
                                },
                            }}
                            getRowClassName={(params) => `matched--${params.row.matched}`}

                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row._id}
                            loading={loading}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            pagination
                            onRowDoubleClick={(params) => {
                                if (params.row.matched) return;
                                setSelectedTransactions([params.row])
                                type === "Credit" ? setOpenInvoiceOrBankModal(true) : setOpenPurchaseOptionsModal(true);
                            }
                            }

                            checkboxSelection
                            onSelectionModelChange={(newRowSelectionModel) => {
                                setSelectionModel(newRowSelectionModel);
                            }}
                            selectionModel={selectionModel}
                        />
                    }
                </Box>


                <InvoiceOrCheckModal
                    open={openInvoiceOrBankModal}
                    onClose={() => setOpenInvoiceOrBankModal(false)}
                    setOpenCheckModal={setOpenCheckModal}
                    setOpenInvoiceModal={setOpenInvoiceModal}
                    setOpenAdvanceModal={setOpenAdvanceModal}
                />
                <ChecksModal
                    open={openCheckModal}
                    onClose={() => {
                        setOpenCheckModal(false)
                        updateTransactions()
                    }}
                    bank={bank}
                    selectedTransaction={selectedTransaction}
                />

                <InvoicesModal
                    open={openInvoiceModal}
                    onClose={() => {
                        setOpenInvoiceModal(false)
                        updateTransactions()
                    }
                    }
                    selectedTransaction={selectedTransaction}
                />

                <AdvanceModal
                    open={openAdvanceModal}
                    onClose={() => {
                        setOpenAdvanceModal(false)
                        updateTransactions()
                    }}
                    selectedTransaction={selectedTransaction}
                />




                <PurchaseOptionsModal
                    open={openPurchaseOptionsModal}
                    onClose={() => {
                        setOpenPurchaseOptionsModal(false)
                    }}
                    selectedTransactions={selectedTransactions}
                    setOpenTransferModal={setOpenTransferModal}
                    setOpenPurchaseInvoiceModal={setOpenPurchaseInvoiceModal}
                    setOpenAccountingModal={setOpenAccountingModal}
                    setOpenPurchaseCheckModal={setOpenPurchaseCheckModal}
                />

                <PurchaseInvoicesModal
                    open={openPurchaseInvoiceModal}
                    onClose={() => {
                        setOpenPurchaseInvoiceModal(false)
                        updateTransactions()
                        setSelectedTransactions([])
                    }
                    }
                    selectedTransactions={selectedTransactions}
                />

                <TransferModal
                    open={openTransferModal}
                    onClose={() => {
                        setOpenTransferModal(false)
                        updateTransactions()
                        setSelectedTransactions([])
                    }}
                    bank={bank}
                    selectedTransactions={selectedTransactions}
                />

                <AccountingModal
                    open={openAccountingModal}
                    onClose={() => {
                        setOpenAccountingModal(false)
                        updateTransactions()
                        setSelectedTransactions([])
                    }}
                    bank={bank}
                    selectedTransactions={selectedTransactions}
                />

                <PurchaseChecksModal
                    open={openPurchaseCheckModal}
                    onClose={() => {
                        setOpenPurchaseCheckModal(false)
                        updateTransactions()
                    }}
                    bank={bank}
                    selectedTransactions={selectedTransactions}
                />


            </Card>
        </div >
    )
}

export default BankView