import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getPendingSalesInvoices = async () => {
    try {
        const response = await axios.get(`${API_URL}invoices/pending`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getPendingSalesInvoicesByTransactionInfo = async (transactionInfo: string) => {
    try {
        const response = await axios.get(`${API_URL}invoices/pending/${transactionInfo}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const SyncSalesInvoices = async () => {
    try { 
        await axios.get(`${API_URL}invoices/synchronize`);
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

export const AssignSalesInvoice = async () => {
    try { 
        await axios.get(`${API_URL}invoices/assign`);
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}