import { Link, useLocation } from "react-router-dom";
import { Routes } from "../config/Routes";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { AuthActions } from "../reducers/AuthReducer";
import { useAppSelector } from "../hooks/storeHooks";
import store from "../store";
import { useState } from "react";
import { AppBar, Drawer, IconButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";


const Menu: React.FC = () => {
  const history = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const route = history.pathname;

  const { user } = useAppSelector((state) => state.authReducer);
  const roles = {
    conciliator: user?.role ? user.role === "conciliator" : false,
    manager: user?.role ? user.role === "manager" : false,
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return store.dispatch({ type: AuthActions.DelToken, payload: null });
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className="flex justify-between">
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setIsNavOpen(true)}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" PaperProps={{ sx: { backgroundColor: "#BF549A", color: "white" } }} open={isNavOpen} onClose={() => setIsNavOpen(false)}>
        <div className="flex flex-col space-y-4 p-4">

          <Link to={Routes.Home}>

            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Home ? "text-pineBlack" : ""
                }`}
            >
              Inicio
            </div>
          </Link>

          <Link to={Routes.Incomes}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Incomes ? "text-pineBlack" : ""
                }`}
            >
              Ingresos
            </div>
          </Link>

          {roles.manager && <Link to={Routes.Expenses}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Expenses ? "text-pineBlack" : ""
                }`}
            >
              Egresos
            </div>
          </Link>}

          <Link to={Routes.Customers}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Customers ? "text-pineBlack" : ""
                }`}
            >
              Clientes
            </div>
          </Link>

          {roles.manager && <Link to={Routes.Suppliers}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Suppliers ? "text-pineBlack" : ""
                }`}
            >
              Proveedores
            </div>
          </Link>}

          <div onClick={handleLogout} style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText>Cerrar sesión</ListItemText>
            <ListItemIcon sx={{ color: "inherit", minWidth: '0', marginRight: '8px' }}>
              <Icon className="w-7" path={mdiLogoutVariant}></Icon>
            </ListItemIcon>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
