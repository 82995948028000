import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  try {
    const {
      data: { token, user },
    } = await axios.post<{ token: string, user: any }>(`${API_URL}auth/login`, {
      username,
      password,
    });

    store.dispatch({ type: AuthActions.SetToken, payload: { token: token, user: user } });
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error) {
    console.log(error);
}
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user")!);
  if (token) {
    try {
      await client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetToken, payload: { token: token, user: user }});
    } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      store.dispatch({ type: AuthActions.SetToken, payload: null });
      
    }
  }
};
