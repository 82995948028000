import { Box, Button, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import BankView from "../BankView";
import { AssignSalesInvoice, SyncSalesInvoices } from "../../../../services/salesInvoiceService";
import Icon from "@mdi/react";
import { mdiCircleSlice1 } from "@mdi/js";

const IncomesView = () => {
    const [tab, setTab] = useState("ESTADO");
    const [loading, setLoading] = useState(false);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    return (
        <div className="p-5 h-full flex flex-col">
            <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="mb-3"
            >
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="Estado" value={"ESTADO"} />
                    <Tab label="Santander" value={"SANTANDER"} />
                    <Tab label="Itau" value={"ITAU"} />
                    <Tab label="Internacional" value={"INTERNACIONAL"} />
                </Tabs>
            </Box>
            <div>
                {loading ? (<div>
                    <Icon spin size={1} path={mdiCircleSlice1}></Icon>
                </div>) : (
                    <div className="flex gap-2">
                        <Button variant="contained"
                            color="primary"
                            onClick={() => {
                                setLoading(true);
                                SyncSalesInvoices().then((res) => {
                                    if (res) {
                                        alert("Facturas sincronizadas con Laudus");
                                    }
                                    else {
                                        alert("Error al sincronizar facturas con Laudus");
                                    }
                                }).finally(() => setLoading(false));
                            }}>
                            Sincronizar facturas con Laudus
                        </Button>
                        <Button variant="contained"
                            color="secondary"
                            onClick={() => {
                                setLoading(true);
                                AssignSalesInvoice().then((res) => {
                                    if (res) {
                                        alert("Facturas asignadas con Laudus");
                                    }
                                    else {
                                        alert("Error al asignar facturas con Laudus");
                                    }
                                }).finally(() => setLoading(false));
                            }}
                        >
                            Asignar facturas con Laudus
                        </Button>
                    </div>)}

                <BankView bank={tab} type={"Credit"} />
            </div>
        </div >
    )
}

export default IncomesView