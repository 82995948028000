import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { useState } from "react";
import { createBankMovement } from "../../../../services/transactionService";


interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    bank: string;
    selectedTransactions: Transaction[] | null;
}

const TransferModal: React.FC<Props> = ({
    open,
    onClose,
    bank,
    selectedTransactions,
}) => {
    const Options = ["ESTADO", "SANTANDER", "ITAU", "INTERNACIONAL"]
    const [newBank, setNewBank] = useState<string>("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (!selectedTransactions || selectedTransactions.length === 0) return;
        setLoading(true)
        const transactionsIds = selectedTransactions.map((transaction) => transaction._id)
        const response = await createBankMovement(transactionsIds, newBank, bank)
        if (response) {
            setNewBank("")
            onClose()
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Traspaso entre cuentas</DialogTitle>

            <DialogContent>
                {
                    selectedTransactions && selectedTransactions.length > 0 ?
                        <Box className="mb-5">
                            <p>Monto total:</p>
                            <p>{selectedTransactions.reduce((acc, transaction) => acc + transaction.amount, 0)}</p>
                            <p>Transacciones seleccionadas: {selectedTransactions.length}</p>
                        </Box>
                        :
                        <p>No hay transacciones seleccionadas</p>
                }

                <p>Banco actual: {bank ? bank : ""}</p>
                <br />
                <p>¿Hacia qué banco hacer el traspaso?</p>

                <Box className="mt-5">
                    <FormControl variant="outlined" sx={{ width: '60%' }}>
                        <InputLabel id="select-new-bank">Nuevo Banco</InputLabel>
                        <Select
                            labelId="select-new-bank"
                            value={newBank ?? ''}
                            onChange={(e) => { setNewBank(e.target.value) }}
                            label="Nuevo Banco"
                        >
                            {Options.filter(option => option !== bank).map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>


            <DialogActions>
                <Button onClick={() => {
                    setNewBank("")
                    onClose()
                }} color="primary">
                    Cancelar
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    disabled={newBank === "" || loading}
                    onClick={handleSubmit}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransferModal;