import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getLaudusCustomersByTransactionInfo = async (transactionInfo: string) => {
    try {
        const response = await axios.get(`${API_URL}laudusCustomers/${transactionInfo}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}